import React from "react";
import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import Rosters from "../components/Rosters";

import { myContentInfo } from "../../my-content";

import NewsBlock from "../components/NewsBlock.js";
import LeadersBlock from "../components/LeadersBlock.js";

const IndexPage = () => {
  return (
    <>
      <Head
        title="49ers Entire Roster"
        description="SF 49ers full test roster for the avid fan."
      />
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main">
                  <Rosters />
                </div>
                <div className="right">
                  {/* - */}
                  <NewsBlock />
                  {/* - */}
                  <LeadersBlock />
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
