import React, { Component } from "react";
import Axios from "axios";
import { filter, has, sortBy } from "lodash";

class Rosters extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isLoading: true,
    theRosters: undefined,
    theOffense: false,
    theDefense: false,
    theSpecialTeams: false,
    expandedOffense: false,
    expandedDefense: false,
    expandedSpecialTeams: false,
  };

  async componentDidMount() {
    Axios.get(`/team-roster.json`).then((result) => {
      if (!result.data || result.status != "200") return;
      const checkingData = result.data;
      if (typeof checkingData == "object") {
        this.loadAllRosters(checkingData);

        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 250);
      }
    });
  }

  constructTheRosterKeys(theName, el) {
    let theOffenceFiltered = filter(el["athletes"], (o) => {
      return o.position == theName;
    })[0];

    let theOffenceConstructed = {};

    theOffenceFiltered.items.forEach((element) => {
      const posName = element.position.name;
      if (!has(theOffenceConstructed, element.position.name)) {
        theOffenceConstructed[element.position.name] = [];
      }
      theOffenceConstructed[posName].push(element);
    });

    Object.keys(theOffenceConstructed).forEach((element, k) => {
      let updateTheSort = theOffenceConstructed[element];

      updateTheSort = sortBy(updateTheSort, [
        (o) => {
          return o.status.type != "active";
        },
      ]);
      theOffenceConstructed[element] = updateTheSort;
    });

    return theOffenceConstructed;
  }

  loadAllRosters(el) {
    let buildTheOffense = this.constructTheRosterKeys("offense", el);
    let sorttheOffense = Object.keys(buildTheOffense)
      .sort()
      .reduce((accumulator, key) => {
        accumulator[key] = buildTheOffense[key];
        return accumulator;
      }, {});

    let preferredOrderOffense = [
      "Quarterback",
      "Running Back",
      "Wide Receiver",
      "Tight End",
      "Offensive Tackle",
      "Center",
      "Guard",
      "Fullback",
    ];

    const finalOrderOffense = {};

    preferredOrderOffense.forEach((preferredOrder) => {
      const makeKey = String(preferredOrder);
      finalOrderOffense[`${makeKey}`] = sorttheOffense[preferredOrder];
    });

    this.setState({
      theOffense: finalOrderOffense,
    });

    const buildTheDefense = this.constructTheRosterKeys("defense", el);

    let sorttheDefense = Object.keys(buildTheDefense)
      .sort()
      .reduce((accumulator, key) => {
        accumulator[key] = buildTheDefense[key];
        return accumulator;
      }, {});

    let preferredOrderDefense = [
      "Defensive End",
      "Defensive Tackle",
      "Linebacker",
      "Safety",
      "Cornerback",
    ];

    const finalOrderDefense = {};

    preferredOrderDefense.forEach((preferredOrder) => {
      const makeKey = String(preferredOrder);
      finalOrderDefense[`${makeKey}`] = sorttheDefense[preferredOrder];
    });

    this.setState({
      theDefense: finalOrderDefense,
    });

    const buildTheSpecialTeam = this.constructTheRosterKeys("specialTeam", el);
    this.setState({
      theSpecialTeams: buildTheSpecialTeam,
    });
  }

  renderStatus(e, owe = false) {
    if (owe) {
      // console.log("🚀 owe ", owe);
      return "status status-hurt";
    }

    if (e.type == "day-to-day") {
      return "status status-day-to-day";
    }

    if (e.type == "practice-squad") {
      return "status status-practice-squad";
    }

    return "status status-active";
  }

  renderThePlayer(item, k) {
    return (
      <div data-player-reference={item.id} className={`li-data`} key={k}>
        <a className="floaty-anchor" id={item.id}></a>
        <div className="img">
          {item?.headshot?.href ? <img src={item?.headshot?.href} /> : ``}
        </div>
        <div className="info">
          <div className="name">{item.fullName}</div>
          <div className="years">Years: {item?.experience?.years}</div>
          <div className="height">Height: {item?.displayHeight}</div>
          <div className="weight">Weight: {item?.displayWeight}</div>
          <div className="college">College: {item?.college?.shortName}</div>

          <div className="birthPlace">
            BirthPlace: {item?.birthPlace?.city}, {item?.birthPlace?.state}
          </div>

          <div
            className={this.renderStatus(
              item.status,
              item?.injuries[0]?.status
            )}
          >
            Status:{" "}
            <span>
              {" "}
              {item?.injuries[0]?.status
                ? item?.injuries[0]?.status
                : item.status.name}{" "}
            </span>
          </div>
        </div>
      </div>
    );
  }

  handleClickPlayer(e) {
    const getTheId = e.target
      .getAttribute("data-linky-reference")
      .replace("#", "");

    const theTargetDom = document.querySelector(
      `.li-data[data-player-reference="${getTheId}"]`
    );

    theTargetDom.classList.add("mr-highlight");
    setTimeout(() => {
      theTargetDom.classList.remove("mr-highlight");
    }, 3000);
  }

  theLinks(links) {
    return Object.keys(links).map((node, q) => {
      return (
        <div className="wraps" key={q}>
          <div className="title">{node}</div>
          <div className="subgroup">
            {links[node].map((item, k) => {
              return (
                <div className="player-container" key={k}>
                  <a
                    data-linky-reference={`#${item.id}`}
                    href={`#${item.id}`}
                    onClick={this.handleClickPlayer}
                  >
                    {item.fullName}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  }

  toggleTargetLinks(theKey) {
    // console.log(
    //   "🚀 ~ file: Rosters.js ~ line 216 ~ Rosters ~ toggleTargetLinks ~ this.state[theKey]",
    //   this.state[theKey] == true
    // );

    const thisOne = this.state[theKey] == true ? false : true;
    const builtEl = {};
    builtEl[`${String(theKey)}`] = thisOne;

    this.setState(builtEl);

    // console.log("🚀 ~ this.state: ", this.state);
  }

  render() {
    const {
      theOffense,
      theDefense,
      theSpecialTeams,
      expandedOffense,
      expandedDefense,
      expandedSpecialTeams,
      isLoading,
    } = this.state;

    if (!theOffense || !theDefense || !theSpecialTeams || isLoading) {
      return (
        <div className="universal-spinner">
          <i className="fa fa-cog fa-spin" />
        </div>
      );
    }

    return (
      <div>
        <div>
          <h2>49ers Team Roster</h2>
          <p>
            View the latest roster information, updated daily. You can also
            quickly expand our quick list of links to view of all active and
            non-active players on the entire roster.
          </p>
        </div>
        <div className="top-links">
          <div
            className={
              expandedOffense ? "link-column link-column--open" : "link-column"
            }
          >
            <h4>
              <a onClick={() => this.toggleTargetLinks("expandedOffense")}>
                Offense {expandedOffense ? " -" : "+"}
              </a>
            </h4>
            <div className="listing-links">{this.theLinks(theOffense)}</div>
          </div>
          <div
            className={
              expandedDefense ? "link-column link-column--open" : "link-column"
            }
          >
            <h4>
              <a onClick={() => this.toggleTargetLinks("expandedDefense")}>
                Defense {expandedDefense ? " -" : "+"}
              </a>
            </h4>

            <div className="listing-links">{this.theLinks(theDefense)}</div>
          </div>
          <div
            className={
              expandedSpecialTeams
                ? "link-column link-column--open"
                : "link-column"
            }
          >
            <h4>
              <a onClick={() => this.toggleTargetLinks("expandedSpecialTeams")}>
                Special Teams {expandedSpecialTeams ? " -" : "+"}
              </a>
            </h4>
            <div className="listing-links">
              {this.theLinks(theSpecialTeams)}
            </div>
          </div>
        </div>
        <div id="roster" className="roster roster-card">
          <div className="the-body">
            {/* - - - - - - - - - - */}
            <div className="the-offense the-outside-grouping">
              <div className="top-large-grouping">Offense</div>
              {Object.keys(theOffense).map((node, q) => {
                return (
                  <div className="grouping-container" key={q}>
                    <div className="group-title">
                      {Object.keys(theOffense)[q]}
                    </div>
                    <div className="subgroup">
                      {theOffense[node].map((item, k) => {
                        return (
                          <div className="player-container" key={k}>
                            {this.renderThePlayer(item, k)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* - - - - - - - - - - */}
            <div className="the-defense the-outside-grouping">
              <div className="top-large-grouping">Defense</div>
              {Object.keys(theDefense).map((node, q) => {
                return (
                  <div className="grouping-container" key={q}>
                    <div className="group-title">
                      {Object.keys(theDefense)[q]}
                    </div>
                    <div className="subgroup">
                      {theDefense[node].map((item, k) => {
                        return (
                          <div className="player-container" key={k}>
                            {this.renderThePlayer(item, k)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>

            {/* - - - - - - - - - - */}
            <div className="the-special-teams the-outside-grouping">
              <div className="top-large-grouping">Special Teams</div>
              {Object.keys(theSpecialTeams).map((node, q) => {
                return (
                  <div className="grouping-container" key={q}>
                    <div className="group-title">
                      {Object.keys(theSpecialTeams)[q]}
                    </div>
                    <div className="subgroup">
                      {theSpecialTeams[node].map((item, k) => {
                        return (
                          <div className="player-container" key={k}>
                            {this.renderThePlayer(item, k)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rosters;
